import React, { useState, useEffect } from 'react'
import { GrFormNext, GrFacebookOption, GrTwitter, GrInstagram, GrYoutube, GrPinterest, GrMap, GrPhone, GrMailOption } from "react-icons/gr";
import '../comonItem/css/footer.css'
export default function Footer() {

  const [social, setSocial] = useState([]);
  useEffect(() => {
    const fetchSocial = async () => {
      try {
        const response = await fetch(
          "https://agril.adaptstoday.co.uk/api/socialmedia"
        );
        const data = await response.json();
        setSocial(data);
      } catch (error) {
        console.error("Error fetching social:", error);
      }
    };

    fetchSocial();
  }, []);


  const [footer, setFooter] = useState([]);
  useEffect(() => {
    const fetchFooter = async () => {
      try {
        const response = await fetch(
          "https://agril.adaptstoday.co.uk/api/settings"
        );
        const data = await response.json();
        setFooter(data);
      } catch (error) {
        console.error("Error fetching Footer:", error);
      }
    };

    fetchFooter();
  }, []);


  return (
    <>
      <div className='section--has-background'>
        <div className='footer-wrapper sectionPadding'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12 col-md-4 footerWidget footerOne'>
                <h3 className='widgetTitle t-white'>Quick a</h3>
                <ul className='footerNav'>
                  <li>
                    <GrFormNext /><a href="/about-us">About Us</a>
                  </li>
                  <li>
                    <GrFormNext /><a href="/agrilfoodsltd">Agril Foods Ltd</a>
                  </li>

                  <li>
                    <GrFormNext /><a href="/georgica-bd-ltd">Georgica BD Ltd</a>
                  </li>

                  <li>
                    <GrFormNext /><a href="/king-feeds-ltd">King Feeds Ltd</a>
                  </li>
                  <li>
                    <GrFormNext /><a href="/management">Our Management</a>
                  </li>
                  <li>
                    <GrFormNext /><a href="/contact">Contact Us</a>
                  </li>

                </ul>
              </div>
              <div className='col-sm-12 col-md-4 footerWidget footerTwo text-center'>
                <h3 className='widgetTitle t-white'>FOLLOW</h3>
                <ul className='socialmedia'>

                  {social.map((item) => {
                    return (
                      <li key={item.id}>
                        <a href={item.smurl} target="_blank">
                          <img src={`https://agril.adaptstoday.co.uk/uploads/socialicon/${item.socialicon}`} alt="Social Icon" width={40} height={40} />
                        </a>
                      </li>
                    )
                  })}

                </ul>

              </div>
              <div className='col-sm-12 col-md-4 footerWidget footerThree'>
                <h3 className='widgetTitle t-white'>CONTACT</h3>
                <div className='address'>
                  <GrMap /><span>{footer.company_address}</span>
                </div>
                <div className='mt-5 phone'>
                  <GrPhone />
                  <span className='phone-wrap'>
                    <a href={`tel:${footer.company_phone}`}>{footer.company_phone}</a>
                  </span>
                  {footer.company_mobile ? (
                    <span className='phone-wrap'>
                      <a href={`tel:${footer.company_mobile}`}>{footer.company_mobile}</a>
                    </span>
                  ) : (
                    <span></span> // Placeholder text when company_mobile is empty
                  )}


                </div>
                <div className='mt-5 eamil address'>
                  <GrMailOption />
                  <a href={`mailto:${footer.company_email}`}>{footer.company_email}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className='copywright'>
        <div className='container'>

          <div className='copywrwp'>
            <p>©2023 Agril Foods LTD. all rights reserved</p>

            <span>|</span>
            <p>Digital transformation by <a className='text-red' href='https://planb.com.bd'>Plan B</a></p>


          </div>
        </div>
      </div>
    </>
  )
}
