import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./index.css";

import Footer from "./components/comonItem/Footer";
import Navber from './components/comonItem/navber';

const SingleEmploy = React.lazy(() => import('./pages/SingleEmploy'));
const Contactus = React.lazy(() => import('./pages/Contactus'));
const Executive = React.lazy(() => import('./pages/Executive'));
const Management = React.lazy(() => import('./pages/Management'));
const Md = React.lazy(() => import('./pages/Md'));
const About = React.lazy(() => import('./pages/About'));
const Georgica = React.lazy(() => import('./pages/Georgica'));
const Kingfeed = React.lazy(() => import('./pages/Kingfeed'));
const Agrilfoods = React.lazy(() => import('./pages/Agrilfoods'));
const ProductDetails = React.lazy(() => import('./pages/ProductDetails'));
const Products = React.lazy(() => import('./pages/Products'));
const Types = React.lazy(() => import('./pages/Types'));
const SubCat = React.lazy(() => import('./pages/SubCat'));
const Category = React.lazy(() => import('./pages/Category'));
const Home = React.lazy(() => import('./pages/Home'));

export default function App() {
  return (
    <>
    <Navber/>
    <Suspense fallback={<div className="container">Loading...</div>}>
     <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/categorys" element={<Category />} />
        <Route path="/category/:slug" element={<SubCat />} />
        <Route path="/category/:slug/:slug" element={<Types />} />
        <Route path="/products" element={<Products />} />     
        <Route path="/product/:slug" element={<ProductDetails />} />   
        <Route path="/agrilfoodsltd" element={<Agrilfoods />} />
        <Route path="/king-feeds-ltd" element={<Kingfeed />} />
        <Route path="/georgica-bd-ltd" element={<Georgica />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/md" element={<Md />} />
        <Route path="/executive" element={<Executive />} />
        <Route path="/management" element={<Management />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/employ/:slug" element={<SingleEmploy />} />
      </Routes>
    </Suspense>
      <Footer/>
    </>
  );
}
